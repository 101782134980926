import { Component } from "@angular/core";
import { GisDataService } from "../gis-data-upload/gis-data-upload.service";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms"; // Ensure Validators is imported
import { DROPDOWN_OPTIONS } from "./models/layer-history-list.model";
import { ApiserviceService } from "../../../../src/app/apiservice.service";
import { NotificationService } from "../services/notification.service";
import {
  initialSharedModel,
  SharedModel,
} from "../shared/sharedmodel/shared.model";
import { CommonfunctionService } from "../../../../src/app/services/commonfunction.service";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogBox } from "../confirm-dialog/confirm-dialogbox";

@Component({
  selector: "app-geo-data-download",
  templateUrl: "./geo-data-download.component.html",
  styleUrls: ["./geo-data-download.component.scss"],
})
export class GeoDataDownloadComponent {
  sharedModel: SharedModel = { ...initialSharedModel }; // use common variable from share model file
  geoDataDownloadFormGroup: FormGroup; // FormGroup for handling form data
  totalCount: number = 0; // Total count of some data, initialize to 0
  displayColumns = [
    "id",
    "process_name",
    "file_name",
    "year",
    "remark",
    "added_date",
    "added_by",
    "updated_by",
    "status",
    "log",
    "action",
  ]; // Columns to be displayed in the table
  selectedData = []; // Selected data to be processed
  startYear: number; // Variable to store the starting year
  currentYear: number; // Variable to store the current year
  options: any = DROPDOWN_OPTIONS;
  selectedYear = new Date().getFullYear();
  isCommaSeparated: boolean = true; // Set to true or false based on your requirements
  // Method to get CSS class and color based on status code
  getStatusClass(status: any): { class: string; color: string } {
    // Mapping of status codes to classes and colors
    const StatusClassMap = {
      "-1": { class: "Failed", color: "red" },
      "1": { class: "Running", color: "blue" },
      "2": { class: "Success", color: "green" },
      "0": { class: "waiting", color: "#000" },
    };
    return (
      StatusClassMap[status] || { class: "status-default", color: "black" }
    );
  }

  // Configuration object for button actions and styles
  buttonConfig = {
    log: [
      {
        label: "Log", // Label for the button
        action: (element: any) => this.downloadLog(element), // Function to execute on click
        style: {
          // Styling for the button
          border: "1px solid #e0e8ff",
          color: "#0033cc",
          backgroundColor: "#e0e8ff",
          hover: {
            // Hover styles
            backgroundColor: "#c0d9ff",
          },
          disabled: {
            // Disabled state styles
            color: "gray",
            backgroundColor: "#f0f0f0",
          },
        },
        class: "log-button", // CSS class for additional styling additional styling
      },
    ],
    action: [
      {
        label: "download",
        action: (element) => this.downloadData(element), // Function to accept request
        style: {
          border: "1px solid #24CB6B",
          color: "#ffffff",
          padding: "5px 10px",
          width: "100px",
          backgroundColor: "#24CB6B",
          hover: {
            backgroundColor: "#d9ffd9",
          },
        },
      },
    ],
  };
  geo_data_download_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  constructor(
    private active: GisDataService, // Injecting GIS data service
    private menus: AdminMenusService, // Injecting admin menus service
    private fb: FormBuilder,
    private api: ApiserviceService,
    private notify: NotificationService,
    private fnc: CommonfunctionService,
    private http: HttpClient,
    private dialog: MatDialog
  ) {
    this.menus.setActive("geo-data-download"); // Setting the active menu item in admin sidebar
    this.active.setActive(""); // Resetting active state in GIS data service
    this.currentYear = new Date().getFullYear();
    this.startYear = 2009;

    // Initializing the FormGroup with controls
    this.geoDataDownloadFormGroup = new FormGroup({});
  }

  ngOnInit(): void {
    this.geoDataDownloadFormGroup = this.fb.group({
      layer_his_control: ["", Validators.required],
      remark: [""],
      uids: [""],
    });
    this.getAllowResourcesApi();
  }
  // getting allow resources
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe((res: any) => {
      this.api.allowResources = res.data;
      this.getAllowResource();
      this.getRequestdata();
    });
  }

  getAllowResource() {
    this.geo_data_download_access = this.fnc.checkResourceAccess(
      "geo_data_download",
      false
    );
  }
  // Handles the chosen year for the date picker
  handleYearSelected(year: number) {
    // if(!this.geo_data_download_access.GET){
    //   this.notify.notify("Access declined: You are not authorized for operation","warn")
    //   return
    // }
    console.log("Selected Year:", year); // Log the selected year to the console
    this.selectedYear = year;
    this.getRequestdata();
  }

  getGeoData() {
    let url = ``; // Placeholder for building a URL for data fetching
  }
  downloadData(element) {
    if (!this.geo_data_download_access.PATCH) {
      this.notify.notify(
        "Access declined: You are not authorized for operation",
        "warn"
      );
      return;
    }
    window.open(element.file_path, "_blank");
  }

  downloadLog(element) {
    if (!this.geo_data_download_access.PATCH) {
      this.notify.notify(
        "Access declined: You are not authorized for operation",
        "warn"
      );
      return;
    }
    window.open(element.logfile.slice(1, -1), "_blank"); // Open the log URL in a new browser tab
  }
  // Handles form submission
  submit() {
    if (!this.geo_data_download_access.POST) {
      this.notify.notify(
        "Access declined: You are not authorized for operation",
        "warn"
      );
      return;
    }
    // Open confirmation dialog
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: "350px",
      data: {
        message: "Are you sure you want to proceed?",
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "YES") {
        this.formSubmit();
      }
    });
  }
  // Perform the actual form submission logic
  formSubmit() {
    const url = `geo-data-list`;
    const body = {
      process_name: this.geoDataDownloadFormGroup.value.layer_his_control,
      added_by: this.api.user_id,
      remark: this.geoDataDownloadFormGroup.value.remark,
      year: this.selectedYear,
      uids: this.geoDataDownloadFormGroup.value.uids,
    };

    this.api.postGesData(url, body).subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.getRequestdata();
          this.geoDataDownloadFormGroup.patchValue({
            remark: "",
            uids: "",
          });
        }
      },
      error: (err: any) => {
        console.error(err);
      },
    });
  }
  onSelectionChange(event) {
    this.geoDataDownloadFormGroup.controls["layer_his_control"].setValue(event);
    this.getRequestdata();
  }

  getRequestdata() {
    // if(!this.geo_data_download_access.GET){
    //   this.notify.notify("Access declined: You are not authorized for operation","warn")
    //   return
    // }
    let url = `geo-data-list?process_name=${this.geoDataDownloadFormGroup.value.layer_his_control}&limit=${this.sharedModel.limit}&offset=${this.sharedModel.offset}&year=${this.selectedYear}&added_by=${this.api.user_id}&is_count=true`;
    this.sharedModel.loader = true;
    this.api.getGesData(url).subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          this.sharedModel.loader = false;
          console.log(res);
          this.notify.notify(res.message, "success");
          this.selectedData = res.data;
          this.totalCount = res.count;
        } else {
          this.notify.notify(res.message, "warn");
          this.sharedModel.loader = false;
          this.selectedData = [];
          this.totalCount = 0;
        }
      },
      error: (err) => {
        this.sharedModel.loader = false;
        console.log(err);
        this.selectedData = [];
        this.totalCount = 0;
      },
    });
  }

  sync() {
    this.getRequestdata(); // Fetch data for the new page
  }

  // Method to handle pagination changes
  onPageChange(event: any) {
    this.sharedModel.limit = event.limit; // Update limit based on pagination event
    this.sharedModel.offset = event.offset; // Update offset based on pagination event
    this.getRequestdata(); // Fetch data for the new page
  }
}
