import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  initialSharedModel,
  SharedModel,
} from "../../../../../../src/app/admin/shared/sharedmodel/shared.model";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ApiserviceService } from "../../../../apiservice.service";
import { NotificationService } from "../../../services/notification.service";

@Component({
  selector: "app-manage-uploaded-csvdata",
  templateUrl: "./manage-uploaded-csvdata.component.html",
  styleUrls: ["./manage-uploaded-csvdata.component.css"],
})
export class ManageUploadedCSVDataComponent {
  @Output() goBackEvent = new EventEmitter<void>();
  @Input() checkResource:any
  selectedRows: any[] = [];
  TableName = [];
  // Array defining columns to be displayed in the table
  displayColumn = [];
  dataSource: any = [];
  // tabledataControl = new FormControl();
  // Filter handling properties
  // filters: any[] = [
  //   { tabledataControl: new FormControl(), tableControl: new FormControl() },
  // ];
  // Configuration object for button actions and styles
  buttonConfig = {
    action: [
      {
        action: (element: any) => this.deleteUploadedData(element), // Function to accept request
        icon: "assets/icon/delete-icon.svg",
        style: {
          width: "20px",
          backgroundColor: "#fff",
        },
        class: "action-button",
      },
    ],
  };

  tables = [];
  sharedModel: SharedModel = { ...initialSharedModel }; // use common variable from share model file
  totalCount: number = this.dataSource; // Total count of records
  selectedData: any[]; // Array to hold fetched data
  selectedColumns: string[]; // Array to define which columns to display in the table
  error_message: string = "";
  selected_table_name: string;
  options: { value: number; viewValue: string }[] = [];
  tableList: any;
  selectedTableColumns: any;
  columnFilteroptions: { value: number; viewValue: string }[] = [];
  manageUploadedDataGroup:FormGroup
  constructor(
    private api: ApiserviceService,
    private notification: NotificationService,
    private fb:FormBuilder,
  ) {}
  onPageChange(event: any) {
    this.sharedModel.limit = event.limit; // Update limit based on pagination event
    this.sharedModel.offset = event.offset; // Update offset based on pagination event
  }

  ngOnInit(): void {
    this.manageUploadedDataGroup = this.fb.group({
      select_table_name:['']
    })
    this.getTables(); // Fetch table names and columns on initialization
   console.log(this.checkResource , "checkResource");

  }

  /**
* This is a function to GET list of Tables from API.
* Resources are checked.
* List of tables are stored in {@link tables} variable.
function
* @api GET /csv-master
* @returns {} Returns void.
*/
  // Method to call the API and fetch table names and column details
  getTables() {
    // this.tableList = [];
    let url = `csv-master?sort_asc=true&sort_by=file_type`;
    this.api.getGmusData(url).subscribe({
      next: (res: any) => {
        this.tableList = res.data;
        this.transformDataType(this.tableList);
      },
      error: (err) => {
        console.error("Error fetching tables: ", err);
        this.TableName = [];
      },
    });
  }

  // Transform the data for dropdown options
  transformDataType(tableList) {
    console.log(tableList);

    tableList.forEach((item) => {
      this.options.push({
        value: item.table_name,
        viewValue: item.file_type,
      });
    });
  }

  /**
   * This function is to GET list of all data from API based on filter selection
   * Data is stored in {@link dataSource} variable.
   function
   * @api GET /csv-data
   */
  getCsvFiles(name = '') {
    // this.dataSource = [];
// limit=${this.sharedModel.limit}&offset=${this.sharedModel.offset}&
    let url = `csv-data?user_id=${this.api.user_id}&tablename=${name}`;
    this.api.getGmusData(url).subscribe((res: any) => {
      // console.log(res);
      // Transform the data
      //const transformedData = res.data//.map(item => item.result);
      if(res.status == 200){
        this.dataSource = res.data;
        this.notification.notify(res.message, 'success')
      }else{
        this.notification.notify(res.message, 'warn')
        this.dataSource = null
      }
    });
  }
  //  Method to handle back navigation
  goBack() {
    this.goBackEvent.emit(); // Emit event to notify parent component
  }
  changeTableSelection(selectedValue: any) {
    // Reset displayColumn array
    this.displayColumn = [];

    // Set the selected table name
    this.selected_table_name = selectedValue;

    // Call the API to get CSV files for the selected table
    this.getCsvFiles(selectedValue);

    // Filter the data for the selected table
    const selectedTable = this.tableList.find(
      (item) => item.table_name === selectedValue
    );

    // If the selected table is found
    if (selectedTable && selectedTable.column_name) {
      this.selectedTableColumns = selectedTable.column_name;

      // Store the column names as an array of strings in displayColumn
      this.displayColumn = Array.from(
        new Set(this.selectedTableColumns.map((item) => item.col_name))
      );

      // Add 'action' column if it's not already present
      if (!this.displayColumn.includes("action")) {
        this.displayColumn.push("action");
      }
      this.displayColumn.forEach((item) => {
        this.columnFilteroptions.push({
          value: item,
          viewValue: item,
        });
      });
    } else {
      // Handle case when no table is found
      console.error("Selected table not found or has no columns");
    }
  }

  onSelectedRowsChange(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }
  // Add a new filter
  addFilter() {}

  // Remove a filter field if there is more than one
  removeFilter(index: number) {}

  // Reset all filters
  resetFilters() {}

  // Apply filters (placeholder for actual filtering logic)
  applyFilters() {}
  // Handle selected rows (e.g., perform batch actions)
  deleteUploadedData(element) {
   if(!this.checkResource.DELETE){
    this.notification.notify("You are not authorized to Delete data", "warn");
    return
   }
    let url = `csv-record?tablename=${this.selected_table_name}&user_id=${this.api.user_id}`;
    if (element) {
      let filter = {};
      filter["id"] = [element.id];
      console.log(filter["id"]);

      // Add the filter as a query parameter (encoded)
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }
    this.api.deleteGmusData(url).subscribe({
      next: (res: any) => {
        console.log(res);
        if (res.status == 200) {
          this.notification.notify("Record deleted successfully", "success");
          this.getTables();
          this.getCsvFiles(this.selected_table_name);
        }
      },
      error: (err: any) => {},
    });
  }
}
