import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ApiserviceService } from "../../../../../../src/app/apiservice.service";
import { TableData } from "../../../shared/components/gis-form-table/gis-form-table.component";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotificationService } from "../../../../../../src/app/admin/services/notification.service";
import { CsvTemplateRequestBody } from "./csvTemplateRequestBody.model";

/**
 * Component for adding and editing CSV templates in the application.
 *
 * This component provides functionality to create a new CSV template or edit an existing one.
 * It allows users to specify various parameters such as file type, table name, and CSV columns,
 * and to validate the input before submission. The component utilizes reactive forms for
 * managing the form state and validation.
 *
 * @component
 * @selector app-add-edit-csv-template
 * @templateUrl ./add-edit-csv-template.component.html
 * @styleUrls ./add-edit-csv-template.component.scss
 *
 * @property {any} editSelectionElement - The element to be edited, if applicable.
 * @property {EventEmitter<void>} goBackEvent - Event emitter to notify the parent component when to navigate back.
 * @property {string} fileType - The type of file for the CSV template.
 * @property {string} tableName - The name of the table associated with the CSV template.
 * @property {TableData[]} tableData - Stores the data fetched from the GIS form table.
 * @property {FormGroup} csvTempFormGroup - Reactive form group for the CSV template.
 * @property {boolean} isEdit - Flag to indicate whether the component is in edit mode.
 * @property {any[]} dataTypes - Array to hold different data types fetched from the API.
 * @property {string} title - Title of the component, changes based on mode (Add/Edit).
 * @property {string} button - Label for the submit button, changes based on mode (Add/Edit).
 *
 * @method ngOnInit - Initializes the component, sets up the form group, fetches data types,
 *                   and prepares for either adding or editing a CSV template.
 * @method goBack - Emits an event to notify the parent component to navigate back.
 * @method getDataTypes - Fetches available data types from the API.
 * @method getRowControls - Retrieves the form controls for a specific row in the CSV template array.
 * @method patchFormValue - Patches existing values into the form if editing an existing template.
 * @method addRow - Adds a new row to the CSV template array, with validation for inputs.
 * @method removeRow - Removes a specific row from the CSV template array.
 * @method submit - Submits the form data to the API for creating or updating a CSV template.
 * @method updateCsvTemplate - Placeholder for future updates related to CSV templates.
 */

@Component({
  selector: "app-add-edit-csv-template",
  templateUrl: "./add-edit-csv-template.component.html",
  styleUrls: ["./add-edit-csv-template.component.scss"],
})
export class AddEditCsvTemplateComponent implements OnInit {
  @Input() editSelectionElement: any;
  @Output() goBackEvent = new EventEmitter<void>();
  fileType: string = "";
  tableName: string = "";
  tableData: TableData[] = [];
  csvTempFormGroup: FormGroup;
  isEdit: boolean = false;
  dataTypes: any[] = [];
  title = "Add CSV Template";
  button: string = "Submit";
  constructor(
    private api: ApiserviceService,
    private fb: FormBuilder,
    private notify: NotificationService
  ) {}

  ngOnInit() {
    this.csvTempFormGroup = this.fb.group({
      file_type: ["", Validators.required],
      table_name: ["", Validators.required],
      csvTempArray: this.fb.array([]),
    });
    this.getDataTypes(); // Fetch data in ngOnInit to ensure it's loaded after initialization
    if (!this.editSelectionElement) {
      this.addRow();
      this.isEdit = false;
      this.title = "Add CSV Template";
      this.button = "Submit";
    } else {
      this.patchFormValue();
      this.isEdit = true;
      this.title = "Edit CSV Template";
      this.button = "Update";
    }
  }

  goBack() {
    this.goBackEvent.emit(); // Emit event to notify parent component
    this.resetForm()
    this.editSelectionElement = [];
  }

  getDataTypes() {
    const url = `data-types`;
    this.api.getGmusData(url).subscribe({
      next: (res: any) => {
        this.dataTypes = res.data; // Assign the data fetched from API
      },
      error: (err) => {
        this.dataTypes = [];
      },
    });
  }
  // Function to get the controls of a specific row
  getRowControls(index: number): FormGroup {
    const rows = this.csvTempFormGroup.get("csvTempArray") as FormArray;
    return rows.at(index) as FormGroup;
  }
  /**
   * patching value in form if already data have
   */
  patchFormValue() {
    this.editSelectionElement.column_name.forEach((element, index) => {
      this.addRow(element, index, "patch");
    });
  }

  /**
 * Adds a new row to the CSV template form array, initializing it with default values and validation rules.
 *
 * This method constructs a FormGroup for a new row in the CSV template and pushes it to the
 * `csvTempFormArray`. If the `type` parameter is set to "patch", it also populates the form
 * with values from the provided `element` and sets the `table_name` and `file_type`
 * in the parent form group from the `editSelectionElement`.
 *
 * @param {any} element - The data object containing values to initialize the row fields.
 *                        Defaults to an empty string if not provided.
 * @param {number} index - The index at which the new row will be added.
 *                         Defaults to 0, which may be used for sorting purposes.
 * @param {string} type - Determines the behavior of the method. If set to "patch",
 *                        it will initialize the form controls with values from the `element` object.
 *                        Defaults to an empty string, indicating a standard addition of a new row.
 *
 * @returns {void} - This method does not return a value; it updates the internal form structure.
 *
 * @example
//  * // Adding a new row with default values
 * this.addRow();
 */

addRow(element: any = "", index: number = 0, type: string = "") {
  // Create a form group for the new CSV template row
  const csvTempArray = this.createCsvTempFormGroup(index);

  // Push the new form group to the form array
  this.csvTempFormArray.push(csvTempArray);

  // If this is an edit operation (patch), populate the form group with existing data
  if (type === "patch") {
    this.populateCsvTempFormGroup(index, element);
  }
}

// Helper method to create a new form group for CSV template row
private createCsvTempFormGroup(index: number) {
  return this.fb.group({
    name: ["", this.getValidators()],
    sort_order: [index],
    required: [false],
    refConditionName: ["", Validators.required],
    data_type: ["", Validators.required],
    refColName: [""],
    refTable: [""],
    json_key: [""],
    col_name: ["", this.getValidators()],
  });
}


// Helper method to get validators for the fields
private getValidators() {
  return Validators.compose([
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), // Ensure not just whitespace
    Validators.maxLength(20), // Maximum length of 20 characters
  ]);
}

// Helper method to populate the form group with existing data during edit
private populateCsvTempFormGroup(index: number, element: any) {
  this.csvTempFormGroup.patchValue({
    table_name: this.editSelectionElement.table_name,
    file_type: this.editSelectionElement.file_type,
  });
  this.csvTempFormArray.controls[index].patchValue({
    name: element.name,
    col_name: element.col_name,
    refTable: element.refrence,
    json_key: element.json_key,
    data_type: element.data_type,
    required: element.required,
    sort_order: index,
  });
}
  /**
   * close window without change
   */
  get csvTempFormArray() {
    return this.csvTempFormGroup.get("csvTempArray") as FormArray;
  }
  /**
   *  remove map layer if layer status is inactive
   * @param i
   * @param csvTempItem
   */
  removeRow(i, csvTempItem) {
    this.csvTempFormArray.removeAt(i);
  }


  /**
 * Submits the CSV template form data to the server for creation or update.
 *
 * This method constructs the request body with the values from the CSV template form, including
 * the file type, column names, table name, and user information. It determines whether to
 * create a new template or update an existing one based on the `isEdit` flag.
 *
 * If the form is for a new template (i.e., `isEdit` is false), it sends a POST request to create
 * the new template. If the form is for editing an existing template (i.e., `isEdit` is true),
 * it sends a PATCH request to update the existing template.
 *
 * Upon receiving a response from the server, it checks the status:
 * - If successful (status 200), it emits a notification to inform the user of the successful
 *   operation and triggers the `goBack` method to navigate back to the previous view.
 * - If unsuccessful, it displays a warning notification with the server's message.
 *
 * @returns {void} - This method does not return a value; it handles the submission process
 *                   and updates the UI based on the response.
 *
 * @throws {Error} - If an error occurs during the API request, it logs the error to the console.
 *
 */

submit() {
  const url = `csv-master`; // Define the URL for the API endpoint
  const body: CsvTemplateRequestBody = {
      file_type: this.csvTempFormGroup.value.file_type,
      column_name: this.csvTempFormArray.value,
      table_name: this.csvTempFormGroup.value.table_name,
      status: 1,
      added_by: this.api.user_id,
  };

  if (this.isEdit) { // If editing an existing template
      body.updated_by = this.api.user_id; // Add the user ID to the request body
      body.id = this.editSelectionElement.id; // Add the template ID to update
      this.api.patchGmusData(url, body).subscribe(res => this.handleResponse(res, "updated"));
  } else { // If creating a new template
      this.api.postGmusData(url, body).subscribe(res => this.handleResponse(res, "created"));
  }
}

/**
 * Handles the response from the server for both create and update operations.
 * @param res - The response object from the server.
 * @param action - The action performed, either "created" or "updated".
 */
private handleResponse(res: any, action: string) {
  if (res.status === 200) {
      this.goBack();
      this.editSelectionElement = null;
      this.notify.notify(`Template has been ${action} successfully`, "success");
  } else { // Handle unsuccessful response
      this.notify.notify(res.message, "warn");
  }
}

// Function to reset the form to "Add Mode"
resetForm() {
  this.csvTempFormGroup.reset(); // Reset the form group
  this.csvTempFormArray.clear(); // Clear the form array
  this.addRow(); // Add an initial empty row for a new template
  this.isEdit = false; // Set to Add mode
  this.title = "Add CSV Template"; // Update title
  this.button = "Submit"; // Update button label
  this.editSelectionElement = null; // Reset edit selection element
}
}

