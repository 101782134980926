<!-- <div class="card"> -->

<div class="form-wrapper">
  <form [formGroup]="layerDataGroup">
    <div class="row">
      <div class="col-6 heading">
        <h5>Spark Calculate-sync</h5>
      </div>
      <div class="col-6 text-align-right">
        <!-- <mat-slide-toggle class="status">
            Server Status
          </mat-slide-toggle> -->
        <div class="year-drop-down">
          <!-- <mat-form-field  > -->
          <mat-form-field appearance="outline" floatLabel="always">
            <input
              matInput
              [matDatepicker]="dp"
              readonly
              (click)="dp.open()"
              placeholder="Select Year"
              min="{{ startYear }}"
              max="{{ currentYear }}"
              [formControl]="date"
            />
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker
              #dp
              startView="multi-year"
              (yearSelected)="chosenYearHandler($event, dp)"
              panelClass="example-month-picker"
            >
            </mat-datepicker>
            <!-- </mat-form-field> -->
          </mat-form-field>
        </div>
        <button type="button" class="filter-icon" (click)="openFilter()">
          <mat-icon svgIcon="filter-icon"></mat-icon>
        </button>
      </div>
    </div>
    <div class="filter-wrapper" *ngIf="isPanelOpen">
      <div class="filter-content">
        <div class="filter-header">
          <p>Filters</p>
        </div>
        <div class="neighbourhood-list">
          <!-- <div class="">
            <p class="filter-title">Select Name</p>
            <div class="bottom-border">
              <mat-radio-group formControlName="filter_neighborhood">
                <ng-container *ngFor="let neighbourhood of filter_list_data">
                <mat-radio-button *ngIf="neighbourhood !== null"
                  class="radio_text"
                  [value]="neighbourhood" >{{ neighbourhood}}</mat-radio-button
                >
              </ng-container>
              </mat-radio-group>
            </div>
          </div> -->
          <div class="status">
            <p class="filter-title">Select Status</p>
            <div class="bottom-border">
              <mat-radio-group formControlName="status">
                <ng-container *ngFor="let item of filteredStatus">
                 <ng-container  *ngFor="let status of status_options">
                <mat-radio-button *ngIf="item == status.status_value"
                  class="radio_text"
                  [value]="status.status_value"
                  >{{ status.label}}</mat-radio-button>
              </ng-container>
            </ng-container>
              </mat-radio-group>
            </div>
          </div>
          <div class="filter-footer">
            <button type="button" class="apply-filter" (click)="applyFilter(layerDataGroup.value.filter_neighborhood, layerDataGroup.value.status)"> <b>Apply filter</b> </button>
            <button type="button" class="reset-all" (click)="resetFilter()">Reset All</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row custom-row">
      <div class="col-12 display-flex  flex-wrap">
        <div class="custom-control">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Select Layer *</mat-label>
            <mat-select
              placeholder="Please select layer"
              formControlName="layerName"
              [(value)]="selected"
              (selectionChange)="getLayerData(selected, null); resetFilter(); layerSelectionReset()"
            >
              <mat-option
                *ngFor="let layerDetail of layerListOptions"
                [value]="layerDetail.value"
              >
                {{ layerDetail.layerName }}
              </mat-option>
            </mat-select>
            <!-- <mat-hint>Select layer for calculate sync</mat-hint> -->
          </mat-form-field>
        </div>
        <div
          class="custom-control"
          *ngIf="layerDataGroup.value.layerName !== ''"
        >
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            placeholder=""
          >
            <mat-label>Neighborhood <span class="required">*</span></mat-label>
            <mat-select
              placeholder="select neighborhood"
              formControlName="neighborhood"
              #neighborhood
              multiple
              (selectionChange)="selectedNeigh($event.value)"
              (openedChange)="searchNeigh = ''"
            >
              <input
                class="searchIn"
                mat-input
                [(ngModel)]="searchNeigh"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Type Name here to search..."
                (keydown)="$event.stopPropagation()"
              />
              <!-- <mat-select-trigger>
                {{neighborhood.value ? layerDataGroup.value : ''}}
                <span *ngIf="neighborhood.value?.length > 1" class="example-additional-selection">
                  +{{neighborhood.value.length - 1}}
                </span>
              </mat-select-trigger> -->
              <mat-option
                *ngFor="
                  let neighbourhood of neighbourhoods
                    | neighFilter : searchNeigh
                "
                [value]="neighbourhood.neigh_uid"
                (click)="searchNeigh = ''"
                >{{ neighbourhood.neigh_name }}</mat-option
              >
            </mat-select>
            <!-- <mat-hint>Select Neighborhood to find report</mat-hint> -->
          </mat-form-field>
        </div>
      <ng-container *ngIf="layerDataGroup.value.layerName == 'setback' ||
         layerDataGroup.value.layerName == 'parcel_visibility' ||
         layerDataGroup.value.layerName == 'amenity_distance'">
        <div class="custom-control" *ngIf="selectedNeighs != null">
          <mat-form-field appearance="outline" floatLabel="always" >
            <mat-label>Select Block</mat-label>
            <mat-select
              placeholder="Please Select Block"
              formControlName="block"
              [disabled]="selectedNeighs?.length > 1"
              required
              multiple
            >
              <mat-option
                [value]="block.block_uid"
                *ngFor="let block of blocks"
              >
                <span *ngIf="block.block_name != ''">{{
                  block.block_name
                }}</span
                ><span *ngIf="block.block_name == ''">{{
                  block.block_uid
                }}</span>
              </mat-option>
            </mat-select>
            <mat-hint>Select a block to find better reports</mat-hint>
          </mat-form-field>
        </div>
      </ng-container>


        <div
          class="custom-control"
          *ngIf="
            layerDataGroup.value.layerName == 'street_rays_180' ||
            layerDataGroup.value.layerName == 'highway_rays_90' ||
            layerDataGroup.value.layerName == 'amenity_distance' ||
            layerDataGroup.value.layerName == 'sikka_parcel'
          "
        >
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            placeholder=""
          >
            <mat-label>Enter Distance</mat-label>
            <input
              type="text"
              matInput
              placeholder="Enter Distance"
              max="200"
              formControlName="distance"
            />
            <mat-hint>Enter Distance in Meters, Max 200 M</mat-hint>
          </mat-form-field>
        </div>
        <div class="custom-control btn-wrapper position-relative">
          <button
            (click)="calculateSyncProcess()"
            type="button"
            class="calculate-btn"
            [disabled]="isCalculating"
          >
            Calculate
          </button>
          <button class="refresh-button" matButton (click)="resetFilter()">
            <img src="assets/icon/sync-process.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="no-data" *ngIf="layerListData?.length == 0">
    <div><img src="../../../../../assets/icon/no-data-found.svg" alt="" /></div>
    <p>No Records Found</p>
  </div>
  <div class="" *ngIf="layerListData?.length > 0">
    <app-data-table
      [layerdata]="layerListData"
      (AfterProcessStopped)="getLayerData()"
      (afterSynced)="getLayerData()"
      [list]="list.asObservable()"
      [totalCount]="totalCount"
      (pageChange)="pageChange($event)"
    ></app-data-table>
  </div>
</div>

<!-- </div> -->

<ngx-loading
  [show]="loader"
  [config]="{ backdropBackgroundColour: 'rgba(255,255,255,0.3)' }"
></ngx-loading>
