<form [formGroup]="csvTempFormGroup">
  <div class="">
    <div class="d-flex align-items-center pt-4 justify-content-between">
      <!-- Back Button (div) with SVG Icon and Translated Text -->
      <div class="d-flex align-items-center justify-content-between">
        <button
          (click)="goBack()"
          type="button"
          class="border-none bg-none cursor-pointer"
        >
          <svg
            class="mx-1"
            xmlns="http://www.w3.org/2000/svg"
            width="13.991"
            height="24.817"
            viewBox="0 0 12.991 23.817"
          >
            <path
              id="XMLID_225_"
              d="M23.5,75.317a1.083,1.083,0,0,0-1.531,0l-10.06,10.06L1.849,75.317A1.083,1.083,0,0,0,.318,76.848L11.144,87.674a1.082,1.082,0,0,0,1.531,0L23.5,76.848A1.082,1.082,0,0,0,23.5,75.317Z"
              transform="translate(87.991 0) rotate(90)"
              fill="#ff9d5a"
              stroke="#ff9d5a"
              stroke-width="2"
            />
          </svg>
        </button>
        <div class="d-flex align-items-center justify-content-between gap-20">
          <h6 class="pl-2 m-0 font-weight-bold">CSV Template Query</h6>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex justify-content-between">
          <button
            class="gis-primary-button"
            (click)="submit()"
            [disabled]="csvTempFormGroup.invalid"
            type="button"
          >
            {{ button }}
          </button>
          <button class="cancel-btn gis-secondary-button" (click)="goBack()">
            Cancel
          </button>
        </div>
      </div>
    </div>

    <!-- <app-gis-form-table
   [dataTypeList]="dataTypes">
  </app-gis-form-table> -->
  </div>
  <div class="csv-wrapper table-wrapper">
    <div class="form-wrapper">
      <div formArrayName="csvTempArray">
        <div class="scroll">
          <table>
            <thead>
              <tr>
                <th class="serial-number">S No.</th>
                <th class="serial-number">Index Name</th>
                <th class="layer-name">Index</th>
                <th class="label">Column</th>
                <th class="store-name">Condition</th>
                <th class="action">Action</th>
              </tr>
            </thead>
            <tbody #scroll>
              <tr
                *ngFor="
                  let csvTempItem of csvTempFormArray.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <td class="serial-number">{{ i + 1 }}</td>
                <td class="layer-name">
                  <div class="error-message-wrapper">
                    <input
                      type="text"
                      matInput
                      placeholder="Enter Index Name *"
                      class="f-14 form-control input-background"
                      formControlName="index_name"
                      required
                    />
                  </div>
                </td>
                <td class="label">
                  <div class="error-message-wrapper">
                    <input
                      type="text"
                      matInput
                      placeholder="eg:- neigh_uid,segment_id, year *"
                      class="f-14 form-control input-background"
                      formControlName="index"
                      required
                    />

                    <div></div>
                  </div>
                </td>
                <td class="json-key">
                  <div class="error-message-wrapper">
                    <input
                      matInput
                      type="text"
                      placeholder="eg:- sub_parameter_id"
                      class="f-14 form-control input-background"
                      formControlName="column"
                    />
                  </div>
                </td>
                <td class="error-message-wrapper">
                  <div class="">
                    <input
                      matInput
                      type="text"
                      class="f-14 form-control input-background"
                      formControlName="condition"
                      placeholder="eg:- IS NOT NULL"
                    />
                  </div>
                </td>
                <td class="action action-coulmn">
                  <div class="d-flex">
                    <button
                      type="button"
                      class="action-button"
                      [ngClass]="{
                        'visibility-hidden': csvTempFormArray.length <= 1
                      }"
                      mat-icon-button
                      (click)="removeRow(i, csvTempItem)"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                    <button
                      *ngIf="
                        i === csvTempFormArray.length - 1 &&
                        csvTempFormArray.length != 10
                      "
                      class="action-button"
                      type="button"
                      mat-icon-button
                      (click)="addRow()"
                    >
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</form>
