<div class="upload-wrapper" *ngIf="!isManageUploaded && !isManageTemplate">
  <div class="d-flex align-items-center justify-content-between flex-wrap">
    <!-- File upload component -->
    <div>
      <app-gis-file-upload
        (afterUpload)="getUploadedData()"
        [upload_access]="momra_zone_code_access"
        [title]="'CSV Data Upload'"
      >
      </app-gis-file-upload>
    </div>

    <!-- Button group -->
    <div class="button-group d-flex flex-wrap">
      <button
        class="change-page-btn parmary-btn"
        (click)="changePage('manage-uploaded-csv')"
      >
        Manage upload CSV
      </button>
      <button
        class="change-page-btn parmary-btn"
        (click)="changePage('manage-template')"
      >
        CSV Template
      </button>
      <!-- Download Button with Menu Trigger -->
      <button
        class="download-btn parmary-btn  d-flex justify-content-center align-items-center"
        [matMenuTriggerFor]="menu"
      >
        <span class="d-flex align-items-center justify-content-between">
        Download
        </span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>

      <!-- Mat Menu for Download Options -->
      <mat-menu #menu="matMenu" class="template-down">
        <button mat-menu-item (click)="downloadCSV('all', tempList)">
          All
        </button>
        <button *ngFor="let table_name of tempList" mat-menu-item (click)="downloadCSV(table_name.table_name, tempList)">
         {{table_name.file_type}}
        </button>
      </mat-menu>

      <div class="loder-btn">
        <button
          mat-mini-fab
          (click)="sync()"
          matTooltip="Sync"
          class="refresh-btn"
        >
          <mat-icon>sync</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- Data table component -->
  <app-gis-data-table
    (pageChange)="onPageChange($event)"
    [data]="selectedData"
    [columns]="selectedColumns"
    [buttons]="buttonConfig"
    [totalCount]="totalCount"
    [getStatusClass]="getStatusClass"
  >
  </app-gis-data-table>

  <!-- Loading indicator -->
  <ngx-loading
    [show]="sharedModel.loader"
    [config]="{ backdropBackgroundColour: 'rgba(255,255,255,0.3)' }"
  ></ngx-loading>
</div>
<ng-container *ngIf="isManageUploaded">
  <app-manage-uploaded-csvdata
    (goBackEvent)="changePage('default')"
    [checkResource]="momra_zone_code_access"
  ></app-manage-uploaded-csvdata>
</ng-container>
<ng-container *ngIf="isManageTemplate">
  <app-manage-csv-template
    (goBackEvent)="changePage('default')"
  ></app-manage-csv-template>
</ng-container>
