
// Create a model file, e.g., `dropdown-option.model.ts`
export interface DropdownOption {
  value: string;
  viewValue: string;
}
export const DROPDOWN_OPTIONS: DropdownOption[] = [
  { value: 'governorates_his', viewValue: 'Governorates History' },
  { value: 'neighbourhoods_his', viewValue: 'Neighbourhoods History' },
  { value: 'blocks_his', viewValue: 'Blocks History' },
  { value: 'streets_his', viewValue: 'Streets History' },
  { value: 'parcels_his', viewValue: 'Parcels History' },
  { value: 'units_his', viewValue: 'Units History' },
  { value: 'project_his', viewValue: 'Project History' },
  { value: 'projects_his_with_uids', viewValue: 'Project History with UIDs' },
];
