// layer-options.model.ts
export interface LayerOption {
  layerName: string;
  value: string;
  url: string;
}

export const layerListOptions: LayerOption[] = [
  {
    layerName: "Parcel Clusters",
    value: "cluster",
    url: "calculate-parcel-cluster-spark"
  },
  {
    layerName: "Direction",
    value: "direction",
    url: "calculate-direction-spark"
  },
  {
    layerName: "Highway/Street facing",
    value: "highway_rays_90",
    url: "calculate-highway-facing-spark"
  },
  {
    layerName: "Street Facing Rays",
    value: "street_rays_180",
    url: "calculate-street-facing-180-spark"
  },
  {
    layerName: "Parcel Visibility",
    value: "parcel_visibility",
    url: "calculate-parcel-visibility-spark"
  },
  {
    layerName: "Setback",
    value: "setback",
    url: "calculate-setback-spark"
  },
  {
    layerName: "Amenity Distance",
    value: "amenity_distance",
    url: "spark-calculation"
  },
  {
    layerName: "Sikka Parcel",
    value: "sikka_parcel",
    url: "spark-calculation"
  }
];
