import { Component } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ApiserviceService } from "../../apiservice.service";
import { NotificationService } from "../services/notification.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { CommonfunctionService } from "../../services/commonfunction.service";
import * as _moment from "moment";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { GisDataService } from "../gis-data-upload/gis-data-upload.service";
import { Subject } from "rxjs";
import { layerListOptions } from "./model/layer-list-options.model";
import { StatusOptions } from "./model/status-options.model";
// tslint:disable-next-line:no-duplicate-imports
// import { default as  Moment } from 'moment';

const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY",
  },
  display: {
    dateInput: "YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};
@Component({
  selector: "app-spark-calculate-sync",
  templateUrl: "./spark-calculate-sync.component.html",
  styleUrls: ["./spark-calculate-sync.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SparkCalculateSyncComponent {
  layerDataGroup: FormGroup;
  neighbourhoods: any[];
  selectedYear = 2024;
  loader: boolean = false;
  searchNeigh: string = "";
  blocks: any[];
  selectedBlock: any[] = [];
  selectedneightUid: any;
  layerListData: [] = [];
  layerTableContent = {};
  isPanelOpen = false;
  layerListOptions = layerListOptions;
  status_options = StatusOptions;
  // layerListOptions = [
  //   {
  //     layerName: "Parcel Clusters",
  //     value: "cluster",
  //   },
  //   {
  //     layerName: "Direction",
  //     value: "direction",
  //   },
  //   {
  //     layerName: "Highway/Street facing",
  //     value: "highway_rays_90",
  //   },
  //   {
  //     layerName: "Street Facing Rays",
  //     value: "street_rays_180",
  //   },
  //   {
  //     layerName: "Parcel Visibility",
  //     value: "parcel_visibility",
  //   },
  //   {
  //     layerName: "Setback",
  //     value: "setback"
  //   },
  //   {
  //     layerName: "Amenity Distance",
  //     value: "amenity_distance"
  //   },
  //   {
  //     layerName: "Sikka Parcel",
  //     value: "sikka_parcel"
  //   },
  // ];
  // status_options = [
  //   {
  //     status_value: "1",
  //     label: "Running",
  //   },
  //   {
  //     status_value: "2",
  //     label: "Success",
  //   },
  //   {
  //     status_value: "-1",
  //     label: "Failed",
  //   },
  //   {
  //     status_value: "-2",
  //     label: "Stopped",
  //   },
  //   {
  //     status_value: "0",
  //     label: "Started",
  //   },
  //   {
  //     status_value: "4",
  //     label: "Synced",
  //   },
  // ];
  selectedNeighs: any;
  startYear: number;
  currentYear: number;
  selected: void;
  offset: number = 0;
  limit: number = 10;
  date = new FormControl(moment());
  totalCount: any;
  is_admin: boolean = false;
  filter_list_data: any;
  filteredStatus: any[];
  isCalculating: boolean = false;
  chosenYearHandler(normalizedYear: _moment.Moment, dp: any) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    // this.selectedYear = ctrlValue
    this.selectedYear = ctrlValue.year();
    dp.close();
    this.getNeighbourhoods();
    this.getLayerData(null, null);
  }
  list: Subject<void> = new Subject<void>();
  calculate_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  constructor(
    private fb: FormBuilder,
    private api: ApiserviceService,
    private notify: NotificationService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private active: GisDataService,
    private menus: AdminMenusService,
    private fnc: CommonfunctionService
  ) {
    iconRegistry.addSvgIcon(
      "filter-icon",
      sanitizer.bypassSecurityTrustResourceUrl("assets/icon/filter-icon.svg")
    );
    this.menus.setActive("spark-calculate-sync");
    this.active.setActive("");
  }

  ngOnInit() {
    this.init();
    this.currentYear = new Date().getFullYear();
    this.startYear = 2009;
    this.layerDataGroup.patchValue({
      layerName: this.layerListOptions[0].value,
    });
    this.getAllowResourcesApi();
  }

  /**
   * Form initialize
   */

  // getting allow resources
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe((res: any) => {
      this.api.allowResources = res.data;
      this.getAllowResource();
      this.getNeighbourhoods();
      this.getLayerData(null, null);
    });
  }

  getAllowResource() {
    this.calculate_access = this.fnc.checkResourceAccess(
      "spark_calculate_sync",
      false
    );
  }
  init() {
    this.layerDataGroup = this.fb.group({
      neighborhood: ["", Validators.required],
      year: [],
      layerName: [""],
      block: [""],
      distance: [""],
      filter_neighborhood: [null],
      status: [null],
    });
  }
  /**
   * getting Neighborhood drop down list
   * @param notDefind
   */
  getNeighbourhoods() {
    this.neighbourhoods = [];
    let url = `neighbourhoods/json`;
    if (this.selectedYear) {
      url += `?year=${this.selectedYear}`;
    }
    this.loader = true;
    this.api.getGpsData(url).subscribe({
      next: (data: any) => {
        this.loader = false;
        if (data.length) {
          this.neighbourhoods = data;
        }
      },
      error: () => {
        this.loader = false;
      },
    });
  }

  /**
   *
   * @param neigh selecting neighbourhoods and send null block after select neighbourhood
   */
  selectedNeigh(neigh) {
    this.layerDataGroup.patchValue({
      block: "",
    });
    if (neigh.length) {
      this.selectedNeighs = neigh;
      this.getBlocks(neigh);
    } else {
      this.selectedNeighs = null;
    }
  }

  /**
   * get block list based on neigh id
   * @param neighUid
   */
  getBlocks(neighUid) {
    let url = `blocks/json?neigh_uid=${neighUid}&return_geometry=true`;

    if (this.selectedYear) {
      url += `&year=${this.selectedYear}`;
    }
    this.blocks = [];
    this.loader = true;
    this.api.getGpsData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.blocks = res;
      },
      error: () => {
        this.selectedBlock = null;
        this.loader = false;
        this.notify.notify("No Blocks found in this Neighbourhood", "warn");
      },
    });
  }

  /**
   *
   * @param layerType
   */
  getLayerData(layerType: any, status) {
    // &limit=${this.limit}&offset=${this.offset}
    // console.log(layerType, status);

    if (!this.calculate_access.GET) {
      this.notify.notify("You are not authorized to get data", "warn");
      return;
    }
    if (this.fnc.checkResourceAccess("all_uploaded_file").GET) {
      this.is_admin = true;
    }
    this.list.next();
    let url = `sync-calculate-process-spark?process_name=${this.layerDataGroup.value.layerName}&sort_asc=false&year=${this.selectedYear}&limit=${this.limit}&offset=${this.offset}`;
    if (this.offset == 0) url += "&is_count=true";
    if (!this.is_admin) url += `&added_by=${this.api.user_id}`;
    if (layerType !== null && this.isPanelOpen)
      url += `&neigh_uids=${layerType}`;
    if (status !== null && this.isPanelOpen) url += `&status=${status}`;
    this.loader = true;
    this.api.getGesData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        if (res && res.status == 200) {
          this.layerListData = res.data;
          this.list.next(res.data);
          // this.formValueReset()
          if (this.offset == 0) {
            this.totalCount = res.count;
          }
          if (layerType == null && status == null) {
            console.log(this.filter_list_data);
            this.getNeighborhood(res.data);
          }
        } else {
          // this.layerListData = [];
          this.loader = false;
          this.notify.notify(res.message, "warn");
        }
      },
      error: (err) => {
        this.layerListData = [];
        // this.filter_list_data = []
        this.loader = false;
        // this.notify.notify(err.error.message, "warn");
      },
    });
  }
  /**
   * filter name list
   * @param datalist
   */
  getNeighborhood(datalist) {
    let list = [];
    let statusList = [];
    datalist.forEach((element) => {
      list.push(element.uid);
      statusList.push(element.status);
    });

    let result = list.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    );
    this.filteredStatus = statusList.filter(
      (item, index) => statusList.indexOf(item) === index
    );
    const uniqueArr = result.filter(
      (item, index) => result.indexOf(item) === index
    );
    this.filter_list_data = uniqueArr;
    console.log(this.filter_list_data);
  }
  formValueReset() {
    this.layerDataGroup.reset({
      neighbourhoods: "",
      blocks: "",
      distance: "",
    });
  }

  calculateSyncProcess() {
    if (!this.calculate_access.POST) {
      this.notify.notify("You are not authorized to calculate data", "warn");
      return;
    }

    this.isCalculating = true;
    const getSelectedType = this.layerDataGroup.value.layerName; // Get selected layer type
    const block = this.layerDataGroup.value.block;
    const distance = this.layerDataGroup.value.distance;

    // Find the selected layer in the list of options
    const selectedLayer = layerListOptions.find(
      (layer) => layer.value === getSelectedType
    );

    if (!selectedLayer) {
      this.notify.notify("Invalid layer selection", "warn");
      this.isCalculating = false;
      return; // Exit if the layer type is not valid
    }

    // Set the appropriate URL based on the selected layer
    const layerTypeUrl = selectedLayer.url;

    // Build the URL for the API request
    let url = `${layerTypeUrl}?process_name=${getSelectedType}&added_by=${this.api.user_id}&year=${this.selectedYear}`;

    if (this.selectedNeighs) {
      url += `&neigh_uid=${this.selectedNeighs}`;
    }

    if (block.length > 0) {
      url += `&block_uid=${block}`;
    }
    if (
      getSelectedType === "street_rays_180" ||
      getSelectedType === "highway_rays_90" ||
      getSelectedType === "amenity_distance" ||
      getSelectedType === "sikka_parcel"
    ) {
      url += `&distance=${distance}`;
    }
    this.loader = true;
    // Make the API call
    this.api.getGesData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.isCalculating = false;
        if (res && res.status === 200) {
          this.notify.notify(res.message, "success");
          this.getLayerData(null, null); // Refresh the layer data
        } else {
          this.notify.notify(res.message, "warn");
          this.loader = false;
        }
      },
      error: (err) => {
        this.layerListData = [];
        this.notify.notify(err.error.message, "error");
        this.loader = false;
      },
    });
}


  openPanel() {
    this.isPanelOpen = !this.isPanelOpen;
  }
  applyFilter(filter_by_name, status) {
    if (filter_by_name == null && status == null) {
      this.notify.notify(
        "Please select at least one option to apply the filter",
        "warn"
      );
      return;
    } else {
      console.log(filter_by_name, status);
      this.getLayerData(filter_by_name, status);
      this.isPanelOpen = false;
    }
  }
  openFilter() {
    this.isPanelOpen = !this.isPanelOpen;
  }
  resetFilter() {
    this.getLayerData(null, null);
    this.isPanelOpen = false;
    this.layerDataGroup.patchValue({
      status: null,
      filter_neighborhood: null,
    });
  }
  layerSelectionReset() {
    this.selectedNeighs = [];
    this.blocks = [];
    this.layerDataGroup.patchValue({
      neighborhood: "",
    });
    this.layerDataGroup.patchValue({
      block: "",
    });
  }
  pageChange(pageDetails) {
    console.log(pageDetails);
    this.limit = pageDetails.limit;
    this.offset = pageDetails.offset;
    this.getLayerData(null, null);
  }
}
